import React, {FC, useCallback} from 'react';

import './header.css'
import './header-media.css'

import {useTranslation} from "react-i18next";
import LangSelect from "./LangSelect";
import Logo from "../Logo";
import useScrollspy from "../../hook/useScrollspy";
import {useNavigate} from "react-router-dom";
import {tubux_href} from "../../data/constants";

const menus = [
  {id: 'section-1', title: 'HOME'},
  {id: 'section-2', title: 'ABOUT_US'},
  {id: 'section-3', title: 'ADVANTAGES'},
  {id: 'section-4', title: 'FAQ'}
]

interface Props {
}

const Header: FC<Props> = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const activeId = useScrollspy(['section-1', 'section-2', 'section-3', 'section-4'], 200);

  const handleRegister = useCallback(() => {
    navigate('/registration')
  }, [navigate]);

  const handleClick = (id: string) => (e: any) => {
    e.preventDefault();
    const element: any = document.getElementById(id);
    const offset = 100;
    const bodyRect: any = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <header>
      <div className="header">
        <div className='header-left'>
          <div className='d-flex align-items-center'>
            <Logo className='header-logo'/>
            <div className="header-separator d-none d-lg-block"/>
            <div className='text-muted-14' style={{width: 132}}>{t('HEADER_TEXT')}</div>
          </div>
          <div className='d-flex align-items-center justify-content-center pt-3 pt-lg-0'>
            <div className='d-flex flex-row-reverse flex-lg-row align-items-center'>
              <div className="header-separator d-none d-lg-block"/>
              <a href={tubux_href} target={'_blank'} className='btn btn-outline-primary'>{t('LOGIN')}</a>
              <div className="header-separator"/>
              <button className='btn btn-primary' type='button' onClick={handleRegister}>{t('REGISTER')}</button>
            </div>
            <div className='d-flex align-items-center d-xl-none'>
              <div className="header-separator"/>
              <LangSelect className='ms-0'/>
            </div>
          </div>
        </div>

        <div className='header-right'>
          <nav>
            <ul className='ps-0'>
              {menus.map((item, i) => {
                return <li key={i}>
                  <a
                    href={`#${item.id}`}
                    className={`${item.id === activeId ? 'active' : ''}`}
                    onClick={handleClick(item.id)}
                  >
                    {t(item.title)}
                  </a>
                </li>
              })}
            </ul>
          </nav>
          <LangSelect className='d-none d-xl-flex'/>
        </div>
      </div>
    </header>
  );
}

export default Header;