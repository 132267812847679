import React, {useEffect} from 'react';

import {Navigate, Route, Routes} from 'react-router-dom';
import HomePage from './pages/HomePage';
import RegistrationPage from './pages/RegistrationPage';
import Ref from './pages/Ref';

function App() {

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let source = urlParams.get('utm_source') || urlParams.get('s');
    if (source) {
      window.localStorage.setItem('source', source);
    }
  }, []);
  return (
    <Routes>
      <Route path="/" element={<HomePage />}/>
      <Route path="/r/:id" element={<Ref/>}/>
      <Route path="/registration" element={<RegistrationPage />}/>
      <Route path="*" element={<Navigate to="/" replace={true}/>}/>
    </Routes>
  );
}

export default App;
