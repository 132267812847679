import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {support_href} from "../../data/constants";

interface Props {
}

const RegistrationSuccessMessage: FC<Props> = () => {
  const {t} = useTranslation();
  return (
    <div>
      <h2 className='mb-0'>{t('SUCCESS_TITLE')}</h2>
      <div className="registration-separator"/>
      <div className='mb-40'>
        <span>{t('SUCCESS_TEXT_1')}</span>
        <a href={`mailto:${support_href}`} className='text-semiBold text-decoration-underline' target='_blank'>{t('SUCCESS_TEXT_2')}</a>
      </div>
    </div>
  );
}

export default RegistrationSuccessMessage;