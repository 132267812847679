import React, {FC, useCallback} from 'react';
import './section_1.css';
import './section-media.css';
import {useTranslation} from "react-i18next";
import youtube from '../../../assets/images/youtube.png';
import arrow_svg from '../../../assets/images/arrow.svg';
import girl from '../../../assets/images/girl.png';
import {useNavigate} from "react-router-dom";
import {ReactSVG} from "react-svg";
import img_1 from './assets/img_1.png';
import img_2 from './assets/img_2.png';
import img_3 from './assets/img_3.png';
import BigActionTextWithArrow from "../../../components/BigActionTextWithArrow";

interface Props {
}

const Section_1: FC<Props> = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleRegister = useCallback(() => {
    navigate('/registration')
  }, [navigate]);

  return (
    <section className='section-1' id='section-1'>
      <div className='section-1-top'>
        <div className='section-1-bg-red'/>
        <div className='section-1-bg-blue'/>
        <div className='section-1-bg-orange'/>
        <h1 className='mb-40 section-title-img'>
          <span>{t('SECTION1_TITLE_1')}</span>
          <img src={youtube} alt="youtube"/>
          <span>{t('SECTION1_TITLE_2')}</span>
        </h1>
        <div style={{maxWidth: 560}} className='mb-40'>
          <span>{t('SECTION1_TEXT_1')} </span>
          <span className='text-semiBold text-decoration-underline action-el' onClick={handleRegister}>
          {t('SECTION1_TEXT_2')}
        </span>
          <span>{t('SECTION1_TEXT_3')}</span>
        </div>
        <button className='btn btn-primary mt-4 btn-lg justify-content-between' type='button' onClick={handleRegister}>
          <span>{t('REGISTER')}</span>
          <ReactSVG src={arrow_svg}/>
        </button>
      </div>
      <div className='section-1-bg'>
        <img src={girl} alt="bg"/>
      </div>
      <div className="d-flex flex-column flex-lg-row align-items-end">
        <div className="section-left">
          <div className="section1-item">
            <img src={img_1} alt="img"/>
            <p dangerouslySetInnerHTML={{__html: t('SECTION1_FOOTER_TEXT_1') || ''}}/>
          </div>
          <div className="section1-item">
            <img src={img_2} alt="img"/>
            <p dangerouslySetInnerHTML={{__html: t('SECTION1_FOOTER_TEXT_2') || ''}}/>
          </div>
          <div className="section1-item">
            <img src={img_3} alt="img"/>
            <p dangerouslySetInnerHTML={{__html: t('SECTION1_FOOTER_TEXT_3') || ''}}/>
          </div>
        </div>
        <BigActionTextWithArrow text='SECTION1_BECOME' className='section-right cur-pointer'/>
      </div>
    </section>
  );
}

export default Section_1;