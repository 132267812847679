import React, {FC} from 'react';
import './section_2.css';
import './section-media.css';
import {useTranslation} from "react-i18next";
import logo from '../../../assets/images/logo.png';
import dashboard from './assets/img.png';


interface Props {
}

const Section_2: FC<Props> = () => {
  const {t, i18n} = useTranslation();

  return (
      <section className='section-2' id='section-2'>
        <h2>
          <span>{t('WHAT_IS')}</span>
          <img src={logo} alt="logo"/>
        </h2>
        <div className='d-flex flex-column-reverse flex-lg-row'>
          <div className='section2-dashboard section-left'>
            <img src={dashboard} alt="dashboard"/>
            <div className='section2-dashboard-items'>
              <div className='text-semiBold' data-idx='01'>{t('SECTION2_DASHBOARD_1')}</div>
              <div className='text-semiBold' data-idx='02'>{t('SECTION2_DASHBOARD_2')}</div>
              <div className='text-semiBold' data-idx='03'>{t('SECTION2_DASHBOARD_3')}</div>
            </div>
            <h3 className='position-relative d-block d-lg-none'>{t('SECTION2_TEXT_3')}</h3>
          </div>
          <div className='section2-info section-right'>
            <div className='text-semiBold'>{t('SECTION2_TEXT_1')}</div>
            <div className=''>{t('SECTION2_TEXT_2')}</div>
            <h3 className='position-relative  d-none d-lg-block'>{t('SECTION2_TEXT_3')}</h3>
          </div>
        </div>
      </section>

  );
}

export default Section_2;