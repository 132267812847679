import React from 'react';
import {useTranslation} from "react-i18next";

const SectionContent1 = () => {
  const {t} = useTranslation();
  return (
    <>
      {/*<div className="row pb-4">*/}
      <div className="pb-4">
        {/*<div className="col-12 col-lg-6">*/}
          <p className='mb-3' dangerouslySetInnerHTML={{__html: t('faq:SECTION_4_ITEM_1_TEXT_1') || ''}}/>
          <p className='mb-2' dangerouslySetInnerHTML={{__html: t('faq:SECTION_4_ITEM_1_TEXT_2') || ''}}/>
          <ul>
            <li>{t('faq:SECTION_4_ITEM_1_TEXT_2_1')}</li>
            <li>{t('faq:SECTION_4_ITEM_1_TEXT_2_2')}</li>
            <li>{t('faq:SECTION_4_ITEM_1_TEXT_2_3')}</li>
            <li>{t('faq:SECTION_4_ITEM_1_TEXT_2_4')}</li>
          </ul>
        </div>
        {/*<div className="col-12 col-lg-6">*/}
        {/*  <div className='section-4-dark_purple'>*/}
        {/*    <p>{t('faq:SECTION_4_ITEM_1_TEXT_3_1')}</p>*/}
        {/*    <p className='mb-2'>{t('faq:SECTION_4_ITEM_1_TEXT_3_2')}</p>*/}
        {/*    <p className='mb-2'>{t('faq:SECTION_4_ITEM_1_TEXT_3_3')}</p>*/}
        {/*    <p className='mb-2'>{t('faq:SECTION_4_ITEM_1_TEXT_3_4')}</p>*/}
        {/*    <p>{t('faq:SECTION_4_ITEM_1_TEXT_3_5')}</p>*/}
        {/*  </div>*/}
        {/*</div>*/}
      {/*</div>*/}
      <p className='mb-3'>{t('faq:SECTION_4_ITEM_1_TEXT_4')}</p>
      <p>{t('faq:SECTION_4_ITEM_1_TEXT_5')}</p>
    </>
  );
}

export default SectionContent1;