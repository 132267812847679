import React, {FC} from 'react';
import './section-3.css';
import './section-media.css';
import {useTranslation} from "react-i18next";
import img_1 from './assets/img_1.png';
import img_2 from './assets/img_2.png';
import {ReactSVG} from "react-svg";
import arrow from "../../../assets/images/arrow_20.svg";
import {support_href, support_title} from "../../../data/constants";

interface Props {
}

const Section_3: FC<Props> = () => {
  const {t} = useTranslation();
  return (
    <section className='section-3' id='section-3'>
      <div className='d-flex mb-80 flex-column flex-lg-row'>
        <div className='section-left'>
          <h2>
            <span>{t('SECTION3_TITLE')}</span>
          </h2>
          <div className='text-bold mb-20'>{t('SECTION3_TEXT_1')}</div>
          <p>{t('SECTION3_TEXT_2')}</p>
        </div>
        <div className='section-right position-relative section-3-img'>
          <img src={img_1} alt="img"/>
        </div>
      </div>
      <div className='d-flex flex-column flex-lg-row'>
        <div className='section-left'>
          <p className='mb-80 d-block d-lg-none'>{t('SECTION3_TEXT_3')}</p>
          <img src={img_2} alt="img" className='w-100 mb-80 m-lg-0'/>
        </div>
        <div className='section-right'>
          <p className='mb-80 d-none d-lg-block'>{t('SECTION3_TEXT_3')}</p>
          <div className='text-semiBold mb-20'>{t('SECTION3_TEXT_4')}</div>
          <div className='d-flex align-items-end '>
            <h3 className='text-nowrap me-3 d-flex flex-column'>
              <span>{t('WRITE_TO')}</span>
              <a href={`mailto:${support_href}`} target='_blank'>{support_title}</a>
            </h3>
            <ReactSVG src={arrow} className='section-3-text-arrow'/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section_3;