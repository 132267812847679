import React, {FC} from 'react';
import './footer.css';
import logo from '../../assets/images/logo_dark.png';
import {privacy_href, support_href} from "../../data/constants";
import {useTranslation} from "react-i18next";

interface Props {
}

const Footer: FC<Props> = () => {
  const {t} = useTranslation();
  return (
    <footer>
      <div className="footer">
        <div className='d-flex align-items-center flex-column flex-lg-row'>
          <div className='d-flex align-items-center'>
            <img src={logo} alt="logo" className='footer-logo'/>
            <div className="header-separator d-none d-lg-block"/>
            <div className='text-muted-14' style={{width: 132}}>{t('HEADER_TEXT')}</div>
          </div>
          <div className="header-separator"/>
          <a href={privacy_href} target='_blank' className='text-muted-14 text-decoration-underline'>{t('PRIVACY')}</a>
        </div>
        <div className="header-separator d-block d-lg-none"/>
        <div className='d-flex flex-column flex-lg-row align-items-center'>
          <div className='text-14 mb-3 m-lg-0 text-center' style={{width: 184}}>{t('SECTION3_TEXT_4')}</div>
          <div className="header-separator  d-none d-lg-block"/>
          <a href={`mailto:${support_href}`} target='_blank'
             className='btn btn-outline-secondary'>{t('WRITE_TO_SUPPORT')}</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;