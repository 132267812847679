import React, {FC, useCallback, useEffect, useState} from 'react';
import SectionItem from "./SectionItem";
import './section_4.css';
import './section-media.css';
import SectionContent1 from "./SectionContent1";
import SectionContent2 from "./SectionContent2";
import SectionContent3 from "./SectionContent3";
import SectionContent4 from "./SectionContent4";
import SectionContent5 from "./SectionContent5";
import SectionContent6 from "./SectionContent6";
import {useTranslation} from "react-i18next";
import BigActionTextWithArrow from "../../../components/BigActionTextWithArrow";
import youtube from "../../../assets/images/youtube.png";

const Section_4: FC = () => {
  const {t} = useTranslation();
  const [activeIdx, setActiveIdx] = useState<any>(0);
  const [ready, setReady] = useState(false);

  const handleToggle = useCallback((idx: number) => () => {
    setActiveIdx((activeIdx: any) => {
      if (activeIdx === idx) return null;
      return idx;
    });
  }, []);

  useEffect(() => {
    setReady(true);
    if (activeIdx >= 0 && ready) {
      const el: any = document.getElementById(`section-4-item_${activeIdx}`);
      if (!el) return;
      setTimeout(() => {
        const section: any = document.getElementById('section-4');
        const top = section?.offsetTop + el?.offsetTop - 180;
        window.scrollTo({top, behavior: 'smooth'});
      }, 300)
    }
  }, [activeIdx]);

  return (
    <section className='section-4' id='section-4'>
      <div className="section-4-bg">
        <div className="section-4-bg-red" />
      </div>
      <div className="section-left d-flex flex-column justify-content-between align-items-start">
        <h2 className="section-text" dangerouslySetInnerHTML={{__html: t('SECTION5_TITLE') || ''}}/>
      </div>
      <div className="section-right">
        <SectionItem idx={0} onClick={handleToggle(0)} title={'SECTION_4_ITEM_TITLE_1'} active={activeIdx === 0}>
          <SectionContent1/>
        </SectionItem>
        <SectionItem idx={1} onClick={handleToggle(1)} title={'SECTION_4_ITEM_TITLE_2'} active={activeIdx === 1}>
          <SectionContent2/>
        </SectionItem>
        <SectionItem idx={2} onClick={handleToggle(2)} title={'SECTION_4_ITEM_TITLE_3'}
                     active={activeIdx === 2}>
          <SectionContent3/>
        </SectionItem>
        <SectionItem idx={3} onClick={handleToggle(3)} title={'SECTION_4_ITEM_TITLE_4'}
                     active={activeIdx === 3}>
          <SectionContent4/>
        </SectionItem>
        <SectionItem idx={4} onClick={handleToggle(4)} title={'SECTION_4_ITEM_TITLE_5'} active={activeIdx === 4}>
          <SectionContent5/>
        </SectionItem>
        <SectionItem idx={5} onClick={handleToggle(5)} title={'SECTION_4_ITEM_TITLE_6'} active={activeIdx === 5}>
          <SectionContent6/>
        </SectionItem>
        <SectionItem idx={6} onClick={handleToggle(6)} title={'SECTION_4_ITEM_TITLE_7'} active={activeIdx === 6}>
          <p>{t('faq:SECTION_4_ITEM_7_TEXT')}</p>
        </SectionItem>
      </div>
      <div className='section4-footer-text'>
        <BigActionTextWithArrow text={'JOIN_US'} text2='TODAY' className='mb-20' />
        <h2 className='section-title-img mb-0'>
          <span>{t('SECTION4_TITLE_1')}</span><br className='d-none d-lg-block'/>
          <img src={youtube} alt="youtube" className='ms-3 ms-lg-0'/>
          <span>{t('SECTION4_TITLE_2')}</span>
        </h2>
      </div>
    </section>
  );
}

export default Section_4;