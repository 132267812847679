import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import arrow from "../assets/images/arrow_40.svg";
import {useTranslation} from "react-i18next";
import {tubux_href} from "../data/constants";

interface Props {
  className?: string;
  text: string;
  text2?: string;
}

const BigActionTextWithArrow: FC<Props> = ({text, text2, className}) => {
  const {t} = useTranslation();
  return (
      <a href={tubux_href} className={`big-arrow-text cur-pointer ${className || ''}`} target='_blank'>
        <span>{t(text)}</span>
        <span className='text-nowrap'> {t(text2 || '')} <ReactSVG src={arrow} className='big-arrow-text-icon' /></span>
      </a>
  );
}

export default BigActionTextWithArrow;